blockquote {
    border-left: 2px solid #377172;
    padding: 10px;
    margin: 10px !important;
}

#cm-editor {
    .image {
        border-radius: 20px;

        .image-content {
            position: relative;

            .image-img {
                display: block;
                max-width: 100%;
                //max-height: 20em;
                box-shadow: none;

                &.selected {
                    box-shadow: 0 0 0 3px #B4D5FF;
                }
            }

            .image-buttons {
                position: absolute;
                top: 10px;
                left: 10px;

                .image-btn {
                    opacity: 0.2;
                    display: inline;
                    margin: 2px;
                    color: white;

                    &.selected {
                        opacity: 1;
                    }
                }
            }

            .resize-handle {
                background-color: #00354e;
                width: 10px;
                height: 10px;
                position: absolute;
                bottom: 0px;
                right: 0px;
                cursor: nwse-resize;
                display: none;
            }

            &:hover .resize-handle {
                display: block;
            }

            .resizing-img {
                position: absolute;
                top: 0;
                left: 0;
                height: auto;
                opacity: 0.5;
            }
        }

        .image-title {
            border: 0px;
            color: grey;
            width: 100%;
            text-align: center;
        }
    }

    /*a.selected {
        box-shadow: 0 0 0 3px #ddd;
    }*/

    p.video-description, p.img-description {
        width: 600px;
        text-align: center;
        color: grey;
        margin: 0px;
    }

    .the-conversation-embed {
        border: 1px solid #eee;
        border-radius: 10px;
        padding: 10px;
        width: 50%;

        img {
            float: left;
            width: 200px;
        }

        input {
            margin-top: 10px;
            width: 100%;
        }

        .btn {
            float: right;
            padding: 0px 4px 4px 4px;
            opacity: 0.5;
            background-color: #00354e;
            border: 0px;
        }

        &:hover {
            .btn {
                opacity: 1;
            }
        }
    }

    .video-embed,
    .image-embed {
        border: 1px solid #eee;
        border-radius: 10px;
        padding: 10px;
    }

    .video {
        width: 600px;
        iframe {
            display: block;
        }

        .video-content {
            width: 600px;
            height: 338px;
            .video-buttons {
                position: absolute;
                .btn {
                    opacity: 0.2;
                    display: inline;
                    margin: 2px;
                    color: white;

                    &.selected {
                        opacity: 1;
                    }
                }
            }

            &:hover {
                .video-buttons {
                    .btn {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

#cm-editor-toolbar {
    background-color: white;
    top: -1px;

    .btn {
        border-radius: 0;
        background-color: white;
        border: 0;
        color: #377172;
        border-bottom: 1px solid white;

        &.active {
            border-bottom: 1px solid #377172;
        }

        &:hover {
            background-color: #8080801f;
        }
    }
}

.image-repo-result {
    position: relative;
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    //border: 1px solid #377172;
    border-radius: 2px;
    float: left;
    margin: 1px;
    cursor: pointer;
    overflow: hidden;

    .image-hover {
        display: none;
    }

    &.selected {
        outline: 1px solid #377172;
    }

    &:hover {
        .image-hover {
            display: block;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            height: 200px;

            label {
                position: absolute;
                color: white;
                bottom: 0;
                padding: 2px;
            }
        }
    }
}