@import "variable";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("react-toastify/dist/ReactToastify.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

$fontL: "Lato", sans-serif;
$fontM: "Montserrat", sans-serif;

body {
  font-family: $fontL;
  margin: 0 auto;
  padding: 121px 0 0;
  background: #f0f3f6;
}

/*------------------------------------------------
#Header
--------------------------------------------------*/

.CM_topHeader {
  background: $blue;
  padding: 7px 0 8px;
  @media only screen and (max-width: 991px) {
    height: 75px;
  }
  .cm_nitificationDropdown {
    .dropdown-toggle {
      text-decoration: none;
      @extend .me-3;

      &:after {
        content: none;
      }
    }

    .dropdown-menu {
      width: 300px;
      .dropdown-item {
        border-left: 3px solid #47bcf0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        @extend .fs-12px;
        color: #0a405a !important;
        white-space: normal;
        @extend .d-flex;
        
        &.unread {
          font-weight: bold;
        }

        &.viewAll {
          background: transparent;
          @extend .justify-content-center;
        }
      }
    }

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      @extend .me-2;
      @extend .rounded-circle;
    }
  }

  .cm_userDropdown {
    .dropdown-toggle {
      text-decoration: none;

      &:after {
        content: none;
      }

      .cm_userimg {
        @extend .w-45px;
        @extend .h-45px;
        @extend .rounded-circle;
        @media only screen and (max-width: 991px) {
          max-width: 40px;
        }
      }
    }

    .badge {
      background-color: #777;
      @include border-radius(10px);
    }

    .dropdown-menu {
      @include box-shadow(rgba(#000, 0.06) 0 0 0 3px);
      border: none;
      width: 200px;

      li {
        position: relative;
        &:hover {
          .sub-menu {
            display: block;
          }
        }
      }

      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: transparent transparent #fff;
        content: " ";
        position: absolute;
        top: -10px;
        right: 13px;
        z-index: 10;
      }

      .dropdown-item {
        color: #0a405a;
        @extend .fs-14px;
      }

      .sub-menu {
        width: 200px;
        display: none;
        padding: 10px;
        position: absolute;
        top: 0;
        margin-top: 0;
        right: 195px;
        left: auto;
        @include border-radius(6px);
        @extend .bg-white;
        @include box-shadow(rgba(#000, 0.06) 0 0 0 3px);

        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          content: " ";
          position: absolute;
          z-index: 10;
          right: -10px;
          top: 6px;
          border-width: 10px 0 10px 10px;
          border-color: transparent transparent transparent #fff;
        }

        &:before {
          content: " ";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 11px 0 11px 11px;
          border-color: transparent transparent transparent rgba(0, 0, 0, 0.06);
          position: absolute;
          top: 5px;
          right: -14px;
        }
      }
    }
  }

  .CM_searchHDR {
    input {
      @include border-radius(60px);
      color: $green;
      background: #f3f6f9;
      padding: 11px 15px;
      border: 2px solid #ebeef1;
      width: 198px;
      height: 42px;
      @extend .me-2;

      &:focus {
        outline: none;
      }
    }

    .btn {
      @extend .text-white;
      padding: 0;
      @extend .mx-3;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .navlink {
    text-decoration: none;
    @extend .text-white;
    margin-right: 25px;
    padding: 17px 0;
  }
  .headerUSDT {
    display: contents;
    
    a:link {
      text-decoration: none;
      color: white !important;
      padding: 4px;
    }
    a:hover {
        text-decoration: none;
    }
  }
}

.CM_menuHeader {
  padding: 5px 0;
  @extend .bg-white;

  .btn-link {
    color: #0a405a;
    text-decoration: none;
    @extend .fs-14px;

    &:after {
      content: none;
    }

    &:hover {
      color: #333;
      background: #e6e6e6;
    }
  }
}

/*------------------------------------------------
header mobile 
--------------------------------------------------*/
.cursor-pointer {
  cursor: pointer;
}
.text_drkgreen {
  color: #0a405a;
}
.link_sml {
  color: #0a405a;
  &:hover {
    text-decoration: underline;
  }
}
@media only screen and (max-width: 991px) {
  .CM_menuHeader {
    .cm_userimg {
      max-width: 45px;
      border-radius: 50%;
    }
    .fl_name {
      color: #0a405a;
      font-size: 16px;
    }
  }
  .mobile_nav_wrapper {
    margin-top: 5px;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 0 3px;
    padding: 15px 15px 5px;
    max-height: 84vh;
    overflow-y: auto;
  }
  .mobile_toggle {
    padding: 0;
    .btn {
      background-color: transparent;
      padding: 3px 0;
      border: none;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .mobile_menu_list {
    padding: 0 0 0 10px;
    border-left: #e9edf2 solid 1px;
    li {
      line-height: 1;
      & + li {
        margin-top: 12px;
      }
    }
    a {
      padding: 0;
      color: #0a405a !important;
      font-size: 14px;
      text-decoration: none;
    }
    .sub-menu {
      padding: 0 0 0 10px;
      margin-top: 12px;
      border-left: #e9edf2 solid 1px;
    }
  }
  .cm_nitificationDropdown {
    button {
      &.btn-link {
        padding-left: 0;
        margin-bottom: 5px;
      }
    }
  }
  .mobile_nav_bottom {
    border-top: 1px solid #e9edf2;
    padding: 12px 0;
    margin-top: 10px;
  }
  .CM_topHeaderRight {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    text-align: left;
    .navlink {
      text-decoration: none;
      color: #00354e;
      font-size: 16px;
      margin-top: 10px;
    }
    .bg-trs {
      text-decoration: none;
      color: #00354e;
      padding: 0;
      margin-top: 10px;
      font-size: 16px;
    }
  }
  .mobile_nav_bottom {
    .CM_megamenu {
      position: static !important;
      transform: translate(0, 0) !important;
    }
  }
  .collapse_wo_loggedin {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .header_wo_loggedin {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
    .cm_form {
      font-size: 24px;
      line-height: 1.3;
      .btn-link {
        font-size: 24px !important;
        line-height: 1.3;
        padding: 5px 0 8px;
        border-bottom: 1px dashed #b14943;
      }
      .btn-green {
        &.arrowBtn {
          line-height: 2.5;
          font-size: 22px !important;
        }
      }
    }
    .cm_greenBox {
      &.show {
        transform: translateY(-50%) scale(1) translateX(-50%);
        left: 50%;
        position: fixed;
      }
      &.ProfessionUl {
        width: 100%;
        padding: 0 15px;
      }
      li {
        font-size: 28px;
        padding: 0 20px 0 20px;
      }
    }
  }
  .hdrwo_login_top {
    border-bottom: 1px solid #e9edf2;
    padding: 15px 15px 20px;

    a {
      text-decoration: none;
      color: #00354e;
      font-size: 16px;
    }
  }
  .CM_megamenu_wo_login {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #f3f6f9;
    padding: 15px;
    & > div {
      margin-top: 7px;
      margin-bottom: 7px;
    }
    .dropdown-item {
      border: 1px solid #ddd;
      border-radius: 3px;
      font-size: 16px;
      background: #fff;
      padding: 0.25rem 1rem;
      color: #0a405a;
      &:hover,
      &:focus {
        color: #555;
        background-color: #f5f5f5;
      }
      &:active {
        color: #555;
        background-color: #f5f5f5;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .header_wo_loggedin {
    .cm_form {
      .btn-green.arrowBtn {
        line-height: 1.25;
        font-size: 18px !important;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        margin: 0 auto;
        &::before {
          padding: 15px;
        }
      }
    }
    .cm_greenBox {
      li {
        font-size: 22px;
        padding: 0 20px 0 20px;
      }
    }
  }
}
/*------------------------------------------------
#Home page
--------------------------------------------------*/

.CM_hero {
  position: relative;
  min-height: 527px;

  video {
    @extend .w-100;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }

  .CM_heroContent {
    position: relative;
    z-index: 1;
    padding: 7% 0 0;

    .CM_heroTitle {
      @extend .text-white;
      @extend .text-uppercase;
      letter-spacing: 0.1em;
      @extend .fs-lg-40px;
      @extend .fs-30px;
      @extend .text-center;
    }

    .CM_heroText {
      @extend .text-white;
      @extend .text-uppercase;
      letter-spacing: 0.1em;
      @extend .fs-lg-16px;
      @extend .fs-13px;
      @extend .text-center;
    }
  }
}

.btn-green {
  background: $green2;
  padding: 12px 10px;
  @extend .text-white;
  @extend .border-0;
  @extend .rounded-sm;
  @extend .fs-14px;
  font-family: $fontM;
  line-height: 1.5;
  @extend .fw-500;
  @include box-shadow(inset 0 -3px 0 rgba(0, 0, 0, 0.1));

  &:hover {
    @include box-shadow(none);
  }

  &.arrowBtn {
    background: #76c3bd;
    line-height: 3;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    color: #fff;
    padding: 0 1em 0 0;
    @extend .fs-25px;
    font-weight: bold;
    letter-spacing: 3px;

    &:before {
      content: "\f30f";
      font-family: "Ionicons";
      color: #fff;
      float: left;
      padding: 0 0.7em;
      margin: 0 0.8em 0 0;
      background: #69b1a9;
    }
  }
}

.btn-blue {
  background: $blue2;
  padding: 12px 10px;
  @extend .text-white;
  @extend .border-0;
  @extend .rounded-sm;
  @extend .fs-14px;
  font-family: $fontM;
  line-height: 1.5;
  @extend .fw-500;
  @include box-shadow(inset 0 -3px 0 rgba(0, 0, 0, 0.1));

  &:hover {
    @include box-shadow(none);
  }
}

.btn-pink {
  background: $pink;
  padding: 12px 10px;
  @extend .text-white;
  @extend .border-0;
  @extend .rounded-sm;
  @extend .fs-14px;
  @extend .fw-500;
  font-family: $fontM;
  line-height: 1.5;
  @include box-shadow(inset 0 -3px 0 rgba(0, 0, 0, 0.1));

  &:hover {
    @include box-shadow(none);
  }
}

.btn-outline-white {
  background: transparent;
  border: 2px solid $white;
  padding: 12px 10px;
  @extend .text-white;
  @extend .rounded-md;
  @extend .fs-14px;
  @extend .fw-500;
  font-family: $fontM;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    @extend .bg-white;
    color: $green !important;
  }
}

.btn-outline-black {
  // background: transparent;
  border: 2px solid #000;
  padding: 12px 10px;
  @extend .rounded-md;
  @extend .fs-14px;
  @extend .fw-500;
  font-family: $fontM;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    @extend .bg-white;
    color: $green !important;
  }
}

.CM_searchCategoryField {
  padding: 12px 16px 11px;
  @extend .bg-white;
  @extend .border-0;
  @extend .rounded-sm;
  appearance: none;
  -webkit-appearance: none;
  color: $green;
  @extend .fs-13px;
  @extend .w-100;

  &:focus {
    outline: none;
  }
}

.CM_categoryBox {
  @extend .bg-white;
  @extend .rounded-sm;
  padding: 2.75em;
  @extend .h-100;

  svg {
    width: 144px;
    height: 144px;
    @extend .mx-auto;
    @extend .d-table;
  }

  h2 {
    margin: 1.25em 0 0.75em;
    @extend .fs-23px;
    line-height: 1.5;
    @extend .text-center;
    color: $green;
  }

  p {
    color: #060212;
    @extend .fs-18px;
    line-height: 33px;
  }
}

.CM_ArticleBox {
  position: relative;
  display: block;
  @extend .rounded-sm;
  @extend .bg-white;
  @include box-shadow(0 2px 0 rgba(0, 0, 0, 0.03));
  @extend .overflow-hidden;

  a {
    @extend .d-block;
  }

  .CM_cover {
    height: 220px;
    object-fit: cover;
  }

  .CM_ArticleBoxContent {
    position: absolute;
    left: 0;
    top: 0;
    height: 220px;
    width: 100% !important;
    padding: 30px;
    background: linear-gradient(
      to bottom,
      transparent 0,
      transparent 0,
      rgba(0, 0, 0, 0.45) 100%
    );

    .CM_logo {
      @extend .d-flex;
      @extend .justify-content-end;

      img {
        max-height: 50px;
        @extend .p-1;
        @extend .bg-white;
        @extend .rounded-sm;
      }
    }

    h2 {
      line-height: 1.5;
      position: absolute;
      bottom: 0;
      left: 19px;
      @extend .fs-20px;
      @extend .text-white;
    }
  }

  .CM_NYR {
    color: $green;
    @extend .fs-13px;
  }

  i.fa.fa-heart-o {
    color: #e27276;
  }

  i.fa.fa-star {
    color: #ccc;
    @extend .fs-14px;
    @extend .me-1;

    &.filled {
      color: #048c96;
    }
  }

  .CM_starValue {
    width: 28px;
    height: 28px;
    line-height: 28px;
    @extend .fs-14px;
    @extend .rounded-circle;
    background: #048c96;
    @extend .text-center;
    @extend .text-white;
    @extend .ms-2;
  }
}

.CM_secTitle {
  @extend .fs-md-26px;
  @extend .fs-20px;
  color: $green;
  @extend .text-center;
}

.CM_secSubTitle {
  @extend .fs-md-16px;
  @extend .fs-14px;
  color: $green;
  @extend .text-center;
}

.CM_TitleIMGBox {
  @extend .h-250px;
  @extend .w-100;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @extend .p-2;
  text-decoration: none;
  @extend .rounded-sm;

  &:hover {
    text-decoration: underline;
    @extend .text-white;
  }

  h2 {
    @extend .fs-md-30px;
    @extend .fs-24px;
    @extend .text-white;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.8);
    @extend .text-center;
    @extend .fw-500;
  }
}

.CM_secHIW {
  background: url("./assets/images/get-paid-per-page-view.jpg") no-repeat center
    top/cover;

  .CM_secHIWinner {
    background: rgba(0, 0, 0, 0.5);
    padding: 12em 0;
  }

  .CM_title {
    @extend .fs-md-48px;
    @extend .fs-32px;
    @extend .text-white;
    @extend .text-center;
    @extend .text-uppercase;
    @extend .fw-800;
    letter-spacing: 0.1em;
  }

  .CM_text {
    @extend .fs-md-16px;
    @extend .fs-14px;
    @extend .text-white;
    @extend .text-center;
    line-height: 2;
    @extend .mx-auto;
    @extend .max-w-825px;
  }
}

.CM_secText {
  @extend .bg-white;
  @extend .py-md-5;
  @extend .py-3;

  .CM_secTitle,
  .CM_secSubTitle {
    @extend .text-start;
  }
}

/*--------------------------------------
#footer
---------------------------------------*/
.CMftr1 {
  background: #2f3339;
  @extend .py-md-5;
  @extend .py-3;

  .CM_social {
    a {
      @extend .fs-14px;
      @extend .text-white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        @extend .text-white;
      }
    }
  }
}

.CMftr2 {
  background: #22262c;
  @extend .py-md-5;
  @extend .py-3;

  a {
    @extend .fs-14px;
    @extend .text-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      @extend .text-white;
    }
  }
}

.CM_copy {
  .text-gray {
    color: #515b6a;
  }
}

/*------------------------------------
#search page
---------------------------------------*/
.search_content {
  .ais-Pagination {
    @extend .mt-5;
    @extend .d-block;
  }
  .ais-Pagination-list {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    .ais-Pagination-item {
      .ais-Pagination-link {
        @extend .d-block;
        padding: 6px 12px;
        margin-left: -1px;
        line-height: 1.42857143;
        color: #337ab7;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #ddd;
        font-size: 20px;
        &.ais-Pagination-link--selected {
          background-color: #048c96;
          border: #048c96;
          font-size: 20px;
          color: #fff;
        }
      }
    }
    .ais-Pagination-item--disabled {
      .ais-Pagination-link {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
      }
    }
  }

  .card-header {
    color: #fff;
    background-color: #048c96;
    padding: 10px 30px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    border-style: solid;
    border-width: 2px;
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 20px;
    margin: -4px 10px 0 0;
    outline: 0;
    padding: 4px !important;
    padding-top: 8px !important;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    min-width: 20px;
    -webkit-appearance: none;
    -webkit-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;
    border-color: #ebeef1;
    border-radius: 4px !important;
  }

  input[type="checkbox"]:checked:before {
    content: "\2713";
    margin: -12px 0 0;
    font-size: 14px;
    color: #3396d1;
    font-weight: bold;
  }

  .ais-RefinementList-list {
    padding: 0;
    list-style: none;
    margin: 0;
    li.ais-RefinementList-item:last-child {
      border-bottom: none !important;
    }
    .ais-RefinementList-item {
      position: relative;
      border-bottom: 1px solid #ddd;
      padding: 5px 30px;
      @extend .fs-13px;
      .ais-RefinementList-label {
        color: #000;
      }
      .ais-RefinementList-count {
        color: $green;
        position: relative;
        &:before {
          content: "(";
        }
        &:after {
          content: ")";
        }
      }
    }
  }

  .search_input {
    padding-left: 14px;

    input {
      background-color: #ebeef1;
      height: 30px;

      &::placeholder {
        color: #7b7b7b !important;
      }

      &:focus {
        background-color: #fff;
      }
    }
  }

  .advanced-filter {
    border: 2px solid #eee;
    padding: 10px 20px;
    background: linear-gradient(
      to bottom,
      #fff9fb 0%,
      #fcfcfc 48%,
      #e5e5e5 100%
    );

    @media only screen and (max-width: 992px) {
      .input-group {
        margin-top: 11px;
        margin-bottom: 11px;
      }
    }

    .search_icon {
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555;
      text-align: center;
      background-color: #eee;
      border: 1px solid #ccc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ais-SortBy-select {
      @extend .text-white;
      appearance: none;
      -webkit-appearance: none;
      background: #048c96 url("./assets/images/caret-down-fill.svg") no-repeat
        scroll calc(100% - 15px) 50% / 15px;
      padding: 12px 40px 12px 20px;
      @include border-radius(6em);
      @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.13));
      border: 0;
      @extend .fs-14px;
      font-family: Montserrat, sans-serif;
      font-weight: 500;

      @media only screen and (max-width: 576px) {
        align-items: center;
      }
      &:focus,
      &:active {
        outline: none;
        background: #048c96 url("./assets/images/caret-down-fill.svg") no-repeat
          scroll calc(100% - 15px) 50% / 15px;
      }
    }
    .show {
      #dropdown-basic {
        background: #449d44;
      }
    }

    .dropdown-menu {
      border: 1px solid rgba(0, 0, 0, 0.15);
      @extend .rounded-sm;
      @include box-shadow(0 6px 12px rgba(#000, 0.18));
      a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        @extend .fs-13px;
        white-space: nowrap;
      }
    }

    .ais-Stats-text {
      color: $green;
      @extend .fs-18px;
    }
  }

  .more_less {
    a {
      color: #000;
      margin-left: 28px;
      display: block;
      text-decoration: none;

      i {
        font-weight: bold;
        font-size: 25px;
        vertical-align: top;
      }
    }
  }
  .ais-SearchBox-form {
    @extend .d-flex;
    button {
      @include border-radius(0);
      & + button {
        @include border-radius(0 4px 4px 0);
      }
    }
    .ais-SearchBox-input {
      padding: 6px 12px;
      @extend .fs-14px;
      @extend .h-40px;
      line-height: 1.42857143;
      color: #555;
      border: 1px solid #ccc;
      @include border-radius(4px 0 0 4px);
      @include box-shadow(inset 0 1px 1px rgba(#000, 0.08));
      @include transition(all ease-in-out 0.15s);
      &:focus {
        outline: none;
        border-color: #66afe9;
        @include box-shadow(0 0 8px rgba(102, 175, 233, 0.6));
      }
    }
  }
}
.ais-SearchBox-submit,
.ais-SearchBox-reset {
  padding: 6px 12px;
  @extend .fs-14px;
  @extend .h-40px;
  line-height: 1;
  color: #555;
  text-align: center;
  background: #eee;
  border: 1px solid #ccc;
}
.result_details {
  margin-bottom: 10px;
  padding: 0px 14px 39px 16px;
  border-radius: 5px;
  .ais-Hits-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  @media only screen and (max-width: 567px) {
    text-align: center;
  }

  .date-apps {
    font-size: 13px;
  }

  h2 {
    margin-bottom: 0px;
    font-size: 24px;

    a {
      color: #048c96 !important;
      font-size: 24px;
      text-decoration: none;
      cursor: pointer;
    }
  }

  img {
    width: 90px;
    height: 90px !important;
  }

  .detailsinfo {
    min-height: 20px;
    color: #377172;
  }

  .date-apps {
    span {
      a {
        text-decoration: none;
        color: #0a405a;
      }
    }
  }

  .border-right {
    border-right: 1px solid #e0e8ec;
  }

  .price_tag {
    display: flex;
    align-self: flex-start;
  }
}

.green_color {
  color: #377172;
}

.range_slider {
  width: 65%;
  margin: 0 auto;
  position: relative;
  padding: 20px 0px;

  .slidevalue_1 {
    font-size: 16px;
    font-weight: 700;
    padding-right: 10px;
  }

  .slidevalue_2 {
    font-size: 16px;
    font-weight: 700;
    right: 50%;
    padding-left: 10px;
  }

  .slider {
    .rangeslider-horizontal .rangeslider__fill {
      height: 100%;
      background-color: #fff;
      border-radius: 10px;
      top: 0;
    }

    .rangeslider__handle {
      background-color: #0e90d2 !important;
      box-shadow: none;

      &::after {
        box-shadow: none;
        background-color: #0e90d2 !important;
      }
    }

    .value {
      display: none;
    }
  }
}

.bg_white {
  background-color: #fff;
  padding-top: 12px !important;
}

/*--------------------------------*/
.CM_megamenu {
  width: 100%;
  position: fixed !important;
  top: 0px !important;
  @include transform(translate(0, 0));
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px;
  background: #f3f6f9;
  @include border-radius(0);
  @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.18));
  @include transform(translate(0px, 114px) !important);

  .dropdown-item {
    @extend .bg-white;
    @extend .rounded-sm;
    border: 1px solid #ddd;
    @extend .fs-14px;
    @extend .mb-3;

    &:hover {
      background: #f5f5f5 !important;
    }
  }
}

.CM_megamenu2 {
  width: 100%;
  position: fixed !important;
  top: 0px !important;
  @include transform(translate(0, 0));
  border: none;
  padding: 15px;
  @extend .bg-white;
  @include border-radius(0);
  @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.18));
  @include transform(translate(0px, 114px) !important);
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  @include box-shadow(none);
}

/*--------------------------------------------
#Submit Article
----------------------------------------------*/
.cm_pageTitle {
  @extend .fs-27px;
  @extend .text-white;
  @extend .text-center;
  padding: 20px 0;
  background: #05202d;
  background-image: url("../src/assets/images/how-it-works.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 5;
   }
  span {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 09202d;
    z-index: 1;
  }
}

.CM_whiteBox {
  @extend .bg-white;
  padding: 27px;
  @include box-shadow(0 2px 0 rgba(0, 0, 0, 0.03));
  @extend .rounded-sm;
  @media only screen and (max-width: 991px) {
    padding: 18px;
}
//   @media only screen and (max-width: 576px) {
//     padding: 10px;
// }
  pre.article-hash {
    border: 1px dotted grey;
    padding: 4px 10px 2px;
    margin: 0px;
    opacity: 0.8;
    font-size: 12px;
    clear: both;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .img-description {
    width: 100%;
    text-align: center;
    color: grey;
    margin: 0px;
  }

  blockquote {
    p {
      margin-top: 1rem;
    }
  }
  .CM_RadioButton,
  .CM_checkbox {
    margin-bottom: 0.25rem !important;
  }
}

label {
  @extend .fs-14px;
  color: $green;
  @extend .w-100;
}

.cm_input {
  @extend .fs-14px;
  color: $green;
  @extend .w-100;
  @extend .rounded-sm;
  border: 2px solid #ebeef1;
  background: #f3f6f9;
  padding: 8px 15px;

  &:focus {
    @extend .bg-white;
    outline: none;
  }
}

select.cm_input {
  @extend .fs-14px;
  color: $green;
  @extend .w-100;
  @extend .rounded-sm;
  border: none;
  background: #f3f6f9;
  padding: 8px 15px;
  appearance: none;
  -webkit-appearance: none;
  @include box-shadow(inset rgba(0, 0, 0, 0.05) 0 -3px 0);

  &:focus {
    outline: none;
    background: #f3f6f9 !important;
  }
}

.CM_checkbox {
  @extend .w-auto;
  @extend .me-2;
  @extend .mb-2;

  b {
    color: #f9e1c9;
    font-weight: bold;
  }

  input[type="checkbox"] {
    border: 2px solid #ebeef1;
    @extend .bg-white;
    @extend .rounded-sm;
    color: #555;
    clear: none;
    cursor: pointer;
    @extend .d-inline-block;
    line-height: 0;
    height: 20px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 4px !important;
    padding-top: 10px !important;
    @extend .text-center;
    vertical-align: middle;
    width: 20px;
    min-width: 20px;
    -webkit-appearance: none;
    -webkit-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;

    &:checked:before {
      content: "✓";
      top: -2px;
      left: -3px;
      @extend .w-15px;
      @extend .h-15px;
      @extend .d-block;
      position: relative;
      background: url(./assets/images/checkmark-outline.svg) no-repeat scroll 0
        0 /14px;
      color: #7FABD7;
    }
    
    &:focus {
      border-color: #66afe9;
      outline: 0;
      @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.08));
    }
  }
}
.CM_RadioButton {
  @extend .w-auto;
  @extend .me-2;
  @extend .mb-2;

  b {
    color: #f9e1c9;
    font-weight: bold;
  }

  input[type="radio"] {
    border: 2px solid #ebeef1;
    @extend .bg-white;
    @extend .rounded-circle;
    color: #555;
    clear: none;
    cursor: pointer;
    @extend .d-inline-block;
    line-height: 0;
    height: 20px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 4px !important;
    padding-top: 10px !important;
    @extend .text-center;
    vertical-align: middle;
    width: 20px;
    min-width: 20px;
    -webkit-appearance: none;
    -webkit-transition: 0.05s border-color ease-in-out;
    transition: 0.05s border-color ease-in-out;

    &:checked:before {
      content: "";
      width: 8px;
      height: 8px;
      top: -6px;
      left: 0px;
      @extend .d-block;
      @extend .rounded-circle;
      position: relative;
      background: #1e8cbe;
    }

    &:focus {
      outline: 0;
    }
  }
}
.btn-dark {
  background: #00354e;
  line-height: 10px;
  margin-top: 26px;
  padding: 16px 0px;
  border: none;
  font-size: 14px;
  color: #f9f6e5;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  @extend .rounded-sm;
  overflow: hidden;
  @extend .w-100;
  cursor: pointer;

  &:hover {
    background: #008791;
  }
}

.cm_confirmModal .modal-content {
  background: #578888;
  @extend .rounded-0;
  border: none;

  .modal-body {
    padding: 1rem 3rem 3rem;

    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 15px;
      line-height: 1.4;
    }
  }

  button.btn-close {
    filter: invert(1);
  }
}

.activeBtn {
  font-weight: bold;
}

.cm_OptionBtn {
  @extend .fs-14px;
  color: #0a405a;
  text-decoration: none;
  cursor: pointer;
}

.cm_tag {
  @extend .d-flex;
  @extend .align-items-center;
  padding: 3px 5px;
  margin: 0 3px 3px 0;
  line-height: 13px;
  color: #333;
  border: 1px solid #aaaaaa;
  @extend .rounded-sm;
  @include box-shadow(0 1px 0 rgba(#000, 0.05));
  background: linear-gradient(
    to bottom,
    #f4f4f4 20%,
    #f0f0f0 50%,
    #e8e8e8 52%,
    #eee 100%
  );

  .btn-link {
    color: #333;
    text-decoration: none;
    @extend .me-2;
    line-height: 13px;
  }
}

.ReactTags__tags {
  .ReactTags__selected {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .flex-wrap;
  }

  .ReactTags__tag {
    @extend .d-flex;
    @extend .align-items-center;
    padding: 3px 5px;
    margin: 0 3px 3px 0;
    line-height: 13px;
    color: #333;
    border: 1px solid #aaaaaa;
    @extend .rounded-sm;
    @include box-shadow(0 1px 0 rgba(#000, 0.05));
    background: linear-gradient(
      to bottom,
      #f4f4f4 20%,
      #f0f0f0 50%,
      #e8e8e8 52%,
      #eee 100%
    );

    .ReactTags__remove {
      color: #333;
      text-decoration: none;
      @extend .ms-2;
      line-height: 13px;
      background: transparent;
      border: none;
    }
  }

  .ReactTags__tagInputField {
    background: transparent;
    border: none;
    width: 220% !important;
    &:focus {
      outline: none;
    }
  }
}

.cm_uploadImage {
  background: #eeeeee;
  position: relative;
  border: 1px solid #dddddd;
  @extend .h-250px;
  //@extend .w-250px;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .fs-20px;
  overflow: hidden;
  color: #bbbbbb;
  text-align: center;
  height: 208px !important;
  @media only screen and (max-width: 1199px) {
    max-width: 248px;
  }
  input {
    width: 200%;
    height: 100%;
    position: absolute;
    left: -110px;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
}

.cm_imageTools {
  position: absolute;
  right: 15px;
  top: 15px;

  .btn {
    @extend .ms-2;
  }
}

.cm_imageTools {
  svg {
    @extend .w-20px;
    @extend .h-20px;
  }
}

.cm_tagInput {
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

/*------------------------------------
# Loogin Page
-------------------------------------*/
.cover-wrapper {
  font-size: 27px;
  padding: 20px 0;
}

.user_login {
  @media only screen and (max-width: 992px) {
    padding: 35px !important;
  }

  @media only screen and (max-width: 576px) {
    margin-bottom: 10px;
  }

  h2 {
    font-size: 16px;
    margin: 1.25em 0;
    padding: 0 0 0.75em;
    border-bottom: 2px solid #e9edf2;
  }
  .content_both .login_cehckbox {
    display: flex;
    align-items: center;
  }

  .remeber_me {
    margin-left: 16px;
  }

  p {
    margin: 0px;

    a {
      color: #0a405a;
      text-decoration: none;
      font-size: 13px;
    }
  }

  .wc-social-login {
    margin: 0 auto;
    width: 50%;

    @media only screen and (max-width: 576px) {
      width: 100%;
    }

    .sc-btn-lin {
      background-image: url(../src/assets/images/linkdein.png);
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      height: 45px;
      width: 250px;
    }

    .sc-btn-tw {
      background-image: url(../src/assets/images/twitter.png);
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      height: 45px;
      width: 250px;

      &:hover {
        background-image: url(../src/assets/images/twt.png);
      }
    }
  }
}

.user_login_search {
  padding: 20px 25px 12px;
  @media only screen and (max-width: 991px) {
    padding: 10px 20px 25px;
  }
  @media only screen and (max-width: 767px) {
    padding: 25px 25px 28px;
  }
  .search_position {
    position: relative;
    max-width: 250px;

    @media only screen and (max-width: 767px) {
      margin-top: 0px !important;
      margin-left: 0px;
    }
    .search-submit_user {
      position: absolute;
      left: 0;
      top: 0;
    }
    input {
      border-radius: 60px !important;
      //   max-width: 150px;
      padding-left: 45px;
      text-align: left !important;

      //   @media only screen and (max-width: 992px) {
      //     width: 95% !important;
      //   }

      &::placeholder {
        color: #7b7b7b !important;
        text-transform: capitalize;
        font-weight: 600;

        @media only screen and (max-width: 992px) {
          padding-left: 0px;
        }
      }
    }

    .search-submit_user {
      i {
        position: absolute;
        top: 11px;
        color: #5a9495;
        left: 30px;
        font-size: 20px;
        font-weight: 300;

        @media only screen and (max-width: 992px) {
          bottom: 34px;
        }
      }
    }
  }
}

/*--------------------------------------------
# Article details
---------------------------------------------*/
.cm_articleBnr {
  position: relative;
  padding: 6.5em 0 5em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .container {
    z-index: 1;
    position: relative;
  }

  .cm_logo {
    @extend .d-flex;
    @extend .justify-content-end;
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;

    img {
      max-height: 100px;
      max-width: 100px;
      @extend .p-1;
      @extend .bg-white;
      @extend .rounded-sm;
    }
  }

  i.fa.fa-star,
  i.fa.fa-star-o {
    color: #fff;
    @extend .fs-14px;
    @extend .me-1;
  }

  .fa-heart,
  .fa-heart-o {
    color: #fff;
    @extend .fs-18px;
    @extend .me-1;
  }

  .cm_reviews {
    @extend .fs-16px;
    @extend .ms-2;
    @extend .text-white;
  }
}

.cm_articleTitle {
  @extend .fs-lg-34px;
  @extend .fs-24px;
  @extend .text-white;
  line-height: 1.4;
}

.cm_maplink {
  @extend .fs-14px;
  @extend .text-white;
  text-decoration: none;

  &.text-green {
    color: $green !important;
  }

  svg {
    width: 24px;
    @extend .me-1;
  }

  &:hover {
    text-decoration: underline;
  }
}

.text-green {
  color: $green;
}

.cm_boxTitle {
  @extend .fs-16px;
  @extend .pb-2;
  border-bottom: 2px solid #e9edf2;
  @extend .mb-2;
  @extend .d-flex;
  @extend .align-items-center;
}

.cm_boxText {
  @extend .fs-14px;
  line-height: 2;
}

.cm_img105 {
  @extend .w-105px;
  @extend .h-105px;
  @extend .rounded-circle;
  object-fit: cover;
  @extend .mx-auto;
  @extend .d-table;
}

.btn-mail {
  @extend .w-50px;
  @extend .h-50px;
  @extend .text-white;
  background: #048c96;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .rounded-circle;
  @extend .justify-content-center;
  @extend .me-2;

  &:hover {
    background: #0cbac7;
  }
}

.btn-info-circle {
    @extend .w-50px;
    @extend .h-50px;
    @extend .text-white;
    background: #3396d1;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .rounded-circle;
    @extend .justify-content-center;
}

.btn-loadmore {
  @extend .fs-14px;
  @extend .text-green;

  &:hover {
    text-decoration: underline;
  }
}

.cm_feed_activity {
  padding: 10px;
  @extend .fs-13px;
  line-height: 2;

  img {
    @extend .w-30px;
    @extend .h-30px;
    object-fit: cover;
    @extend .me-1;
  }

  a {
    @extend .text-green;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .feed-time {
    color: grey;
    font-style: italic;
    padding-left: 5px;
  }

  &:hover {
    background: #eee;
  }
}

.price-sticky-top {
  position: sticky;
  top: 150px;
}

.modal-content {
  padding: 2em;
  @include border-radius(25px);
}

button.btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
  background: #5a9495;
  @extend .rounded-circle;
  opacity: 1;
  &:after {
    content: "\f129";
    font-family: Ionicons;
    @extend .text-white;
    top: -3px;
    position: relative;
  }
}

h2.popup-title {
  font-size: 24px;
}

.modal-social-login {
  text-align: center;

  a {
    display: block;
  }
}

.Login_lg_btn button {
  width: 100%;
}

.lost_your_pass {
  p {
    a {
      color: #0a405a;
      text-decoration: none;
      font-size: 13px;
      cursor: pointer;
    }
  }
}

.mylogin {
  //border-left: 1px solid #ddd !important;
  border-right: none !important;
  padding-left: 11% !important;
  padding-right: 11% !important;
}

.invitation_link {
  color: #377172;
  text-decoration: none;
  font-size: 15px;
  font-weight: 300;

  button {
    text-decoration: none !important;
    color: orange;
    margin-left: 4px;
    border: none;
    background-color: transparent;
  }
}

.model_width {
  .modal-xl {
    min-width: 70%;
  }

  .btn-close {
    background-color: #5a9495;
    border-radius: 50%;
    color: #fff;
    opacity: 1;
  }
}

.page-title.cover-wrapper {
  color: #377172;
  font-size: 27px;

  @media only screen and (max-width: 992px) {
    font-size: 24px;
  }
}

.registration-right {
  padding-left: 20px;
  animation: fadeInRight 1s ease-in-out;

  @media only screen and (max-width: 1025px) {
    padding-left: 0px;
    margin-top: 18px;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(300px);
    }

    to {
      opacity: 1;
    }
  }

  h2 {
    color: #377172;
    font-size: 27px;
    padding: 0 0 0.75em;
    border-bottom: 2px solid #e9edf2;
    margin-bottom: 21px;
    width: 85%;

    @media only screen and (max-width: 1025px) {
      width: 100%;
      font-size: 21px;
    }
  }

  .icon_text_round {
    display: flex;
    align-items: end;
    margin-bottom: 57px;

    @media only screen and (max-width: 576px) {
      display: block;
    }

    .icon_round_left, .icon_round_custom {
      width: 75px;
      height: 75px;
      min-width: 75px;
      line-height: 63px;
      margin-right: 30px;
      font-size: 28px;
      text-align: center;
      color: #008791;
      position: relative;
      border: 3px solid #008791;
      border-radius: 50%;
      display: inline-table;
      position: relative;

      @media only screen and (max-width: 1025px) {
        width: 27%;
        line-height: 54px;
        height: 36px;
      }

      @media only screen and (max-width: 992px) {
        width: 23%;
        height: auto;
      }

      @media only screen and (max-width: 576px) {
        width: 27%;
        height: auto;
        line-height: 32px;
        font-size: 20px;
      }

      &:hover {
        color: #fff;

        &:before {
          background-color: #008791;
        }

        &:after {
          -webkit-transform: scale(1.3, 1.3);
          -ms-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3);
          width: 75px;
          height: 75px;
          min-width: 75px;
          position: absolute;
          right: 32px;
          top: -3px;
          border-radius: 50%;
          background: #008791;
          transition: transform 0.2s, opacity 0.3s;
          transform: scale(1.3, 1.3);
          opacity: 0;
        }
      }
    }

    .icon_round_right {
      width: 69%;
      border-bottom: 2px solid #e9edf2;
      margin: 8px 97px 9px auto;

      @media only screen and (max-width: 1025px) {
        width: 100%;

        margin: auto;
      }

      h4 {
        color: #377172;
        font-size: 15px;
      }
    }
  }

  .icon_round_custom:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: .3s;
    border-radius: 50%;
    background: none !important;
  }
  .icon_round_custom:hover:before {
      background: #008791 !important;
  }

  .icon_round_left, .icon_round_custom {
    &:hover {
      background-color: #008791;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: #f2f2f2;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      background: #fff;
      border-radius: 50%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -3px;
      top: -3px;
      bottom: -3px;
      right: -3px;
      z-index: 1;

      width: 75px;
      height: 75px;
    }

    i {
      position: relative;
      z-index: 2;
      margin: 0 0 0 2px;
      top: -2px;
    }
  }
}

.icon_round_custom:hover svg {
  filter: grayscale(1);
  position: relative;
  z-index: 9;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.allredy_have_account {
  a {
    color: orange !important;
    text-decoration: none;
  }
}

.padding_inner {
  padding-left: 20px;
  padding-right: 20px;

  .req_an_invitation {
    border-right: 1px solid #ddd;
    padding-right: 7%;

    @media only screen and (max-width: 992px) {
      border-right: 0px;
      padding-right: 0p;
    }

    input.form-control {
      padding: 12px 14px;
    }

    .content_both {
      color: #377172;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 30px;
      margin-left: -107px;

      @media only screen and (max-width: 1025px) {
        margin-left: auto;
      }

      input[type="radio"] {
        border-style: solid;
        border-width: 2px;
        background: #fff;
        color: #555;
        clear: none;
        cursor: pointer;
        display: inline-block;
        line-height: 0;
        height: 20px;
        margin: -4px 4px 0 0;
        outline: 0;
        padding: 4px !important;
        padding-top: 10px !important;
        text-align: center;
        vertical-align: middle;
        width: 20px;
        min-width: 20px;
        -webkit-transition: 0.05s border-color ease-in-out;
        transition: 0.05s border-color ease-in-out;
        border-radius: 50%;
        margin-left: 14px;
        margin-right: 6px;
      }
    }
  }
}

.model_body.modal-body {
  overflow-x: hidden;
  height: 550px;
  display: block;
}

/*-------------------------------------------
#TimSpector
--------------------------------------------*/
.cm_TimSpectorCover {
  position: relative;
  padding: 4em 0 5em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .container {
    z-index: 1;
    position: relative;
  }

  .cm_TimSpectorImg img {
    @extend .w-75px;
    @extend .h-75px;
    @extend .rounded-circle;
    object-fit: cover;
  }

  .fa-heart,
  .fa-heart-o {
    color: #fff;
    @extend .fs-18px;
    @extend .me-1;
  }

  .cm_reviews {
    @extend .fs-16px;
    @extend .ms-2;
    @extend .text-white;
  }
}

.cm_TimSpectorTitle {
  @extend .fs-lg-26px;
  @extend .fs-20px;
  @extend .text-white;
  line-height: 1.4;
}

.cm_FilterBtn {
  @extend .fs-13px;
  text-decoration: none;
  color: #0a405a;

  &:hover {
    text-decoration: underline;
    color: #0a405a;
  }
}

.cm_spectorTitle {
  @extend .fs-20px;
  @extend .text-green;
}

.cm_imglistspector img {
  @extend .min-w-90px;
  @extend .w-90px;
  @extend .h-90px;
  @extend .rounded-circle;
}

.cm_listspectorItem a {
  text-decoration: none;

  &:hover {
    @extend .text-green;
  }
}

.cm_img32px {
  width: 32px !important;
  height: 32px !important;
  object-fit: cover !important;
  @extend .me-1;
  @extend .mb-1;
}

/*---------------------------------------------------*/
.cm_form {
  color: $green;
  margin: 0.3em auto 0 auto;
  font-size: 55px;
  line-height: 1.5;

  .btn-link {
    color: #a8b6b5;
    font-size: 55px !important;
    line-height: 1.5;
    padding: 5px 0 18px;
    border-bottom: 1px dashed #b14943;

    &:hover {
      background: transparent;
      color: #a8b6b5;
    }
  }

  .cm-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    z-index: 9999;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.3s, opacity 0.3s;
    -moz-transition: visibility 0s 0.3s, opacity 0.3s;
    transition: visibility 0s 0.3s, opacity 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
    }
  }

  .cm-form-text {
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    padding: 0.4em 1em;
    color: rgba(0, 0, 0, 0.2);
    border-top: 1px dashed rgba(255, 255, 255, 0.7);
  }
}

.cm_greenBox {
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  background: #76c3bd;
  left: -0.5em;
  top: 50%;
  font-size: 44px;
  color: #fff;
  text-align: center;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;

  &.ProfessionUl {
    width: 850px;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-transition: visibility 0s 0s, opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: visibility 0s 0s, opacity 0.3s, -moz-transform 0.3s;
    transition: visibility 0s 0s, opacity 0.3s, transform 0.3s;
  }

  li {
    cursor: pointer;
    padding: 0 50px 0 20px;
    position: relative;

    &.cm_checked {
      color: #478982;

      &:after {
        content: "\f122";
        font-family: "Ionicons";
        color: #0a405a;
        position: absolute;
        right: 0.5em;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    .btn-green {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.cm_keywordform {
  input {
    width: 237px;
    background: transparent;
    border: none;
    color: #fff;

    &:focus {
      outline: none;
    }
  }
}
*:focus {
  outline: none;
}
/*------------------------------------------------
#Editer content
--------------------------------------------------*/
.cm_content {
  @extend .text-green;
  .embed-responsive.embed-responsive-16by9 {
    padding: 0;
    padding-bottom: 56.25%;
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  p {
    line-height: 2;
  }
  h1 {
    font-size: 2em;
    margin: 0.2em 0;
    font-weight: 900;
    line-height: 1.4;
    text-transform: uppercase;
  }
  h3 {
    font-size: 0.9em;
    margin: 1.25em 0;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    padding: 0 0 0.75em;
    border-bottom: 2px solid #e9edf2;
  }
  .cm_135pxRounded {
    width: 120px;
    height: 120px;
    @extend .rounded-circle;
    object-fit: cover;
  }
  .cm_340pxrounded {
    width: 300px;
    height: 300px;
    max-width: 100%;
    @extend .rounded-circle;
    object-fit: cover;
  }
  figure.hdr {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #000;
    &:hover {
      img {
        opacity: 0.5;
        @include transform(scale(1));
      }
    }
    img {
      position: relative;
      display: block;
      width: 100%;
      opacity: 0.8;
      @include transition(all 0.35s);
      @include transform(scale(1.2));
    }
    div {
      height: 100%;
      z-index: 0;
    }
    figcaption {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 2.5em;
      color: #fff;
      font-size: 1.55em;
      text-align: center;
      box-sizing: border-box;
      z-index: 1;
      text-align: right;
      p {
        position: absolute;
        right: 50px;
        bottom: 10%;
        margin: 0;
        z-index: 10000;
        letter-spacing: 1px;
        font-size: 48.5%;
        text-transform: uppercase;
      }
    }
  }
}
.social_btn {
  @extend .h-20px;
  padding: 1px 12px;
  background: #1d9bf0;
  @extend .text-white;
  @extend .rounded-lg;
  @extend .fw-500;
  @extend .fs-13px;
  text-decoration: none;
  cursor: pointer;
  &.linkdin {
    background: #0e76a8;
  }
  &:hover {
    background: #0c7abf;
    @extend .text-white;
  }
}
.comment-author .avatar {
  height: auto;
  @extend .rounded-circle;
}
.comment-rating {
  margin-right: 2em;
  margin-top: 3px;
  background: #048c96;
  color: #fff;
  float: left;
  @extend .fs-11px;
  @extend .text-center;
  line-height: 36px;
  @extend .rounded-circle;
  width: 36px;
  height: 36px;
  margin: 3px 2em 1.5em 0;
}
.commentlist .star-rating {
  display: inline-block;
  margin-right: 2em;
  margin-bottom: 0;
}
.commentlist .ion-star {
  color: #ccc;
  &.filed {
    color: #048c96;
  }
}
.commentlist li {
  .bg {
    @extend .mb-3;
    @extend .p-3;
    background: #f9f9f9;
  }
  ul {
    @extend .list-unstyled;
    @extend .ps-2;
    @extend .ms-4;
    @extend .border-start;
  }
}
.btn-link {
  .ion-close-round {
    color: #a00;
  }
}
.cm_table {
  @include box-shadow(0 2px 5px rgba(105, 108, 109, 0.7));

  td {
    color: $green;
    @extend .fs-13px;
    vertical-align: middle;
    a {
      color: $green;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .btn-link {
      color: $green;
      @extend .fs-13px;
      text-decoration: none;
      span {
        @extend .me-1;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  th {
    background: #00354e;
    @extend .text-white;
    border: 1px solid #00354e;
  }
}
/*-------------------------------------------------
#My Account
--------------------------------------------------*/

.edit_account {
  ul {
    li {
      button {
        color: #0a405a;
        font-size: 13px;

        &:hover {
          background-color: #eee;
          color: #0a405a;
        }
      }
    }
  }

  .password_sec {
    input {
      &:focus {
        border-color: #66afe9;
        outline: none;
        @include box-shadow(0 0 8px rgba(102, 175, 233, 0.6));
      }
      &::placeholder {
        color: #7b7b7b !important;
      }
    }
  }

  button[disabled] {
    cursor: default;
    background: #9b9b9b;
  }

  textarea {
    &::placeholder {
      color: #939393;
      font-weight: 600;
    }
  }

  span {
    .social-badge {
      width: 150px;
    }
  }

  .social-loginprofiles {
    tr {
      th {
        padding: 5px 10px;
        font-size: 13px;
      }

      td {
        padding: 4px 7px;
      }
    }

    .social-badge {
      background-color: #000 !important;
      padding: 4px 15px 6px 19px;
      color: #fff;
      width: 150px !important;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      line-height: 28px;
      font-size: 100%;
      font-family: inherit;
      font-weight: normal;
      text-decoration: none;
      overflow: visible;
      border-radius: 2px;
      white-space: nowrap;
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
      border: 0;
      color: #fff;
      background: #333;
      padding: 0 0.5em;
      margin: 6px 0;
      font-size: 14px;

      i {
        margin-right: 12px;
        position: relative;

        &:after {
          content: "";
          height: 31px;
          width: 1px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.3);
          top: -6px;
          left: 21px;
        }
      }
    }
  }

  .table-bordered {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
/*-------------------------------------------------
#My listings
---------------------------------------------------*/
.cm_userheader {
  background: #00354e;
  color: #fff;
  padding: 5px 10px;
  @extend .fs-13px;
  a {
    @extend .text-white;
    @extend .text-decoration-none;
    @extend .mx-1;
    @extend .fs-13px;
    &:hover {
      @extend .text-decoration-underline;
    }
  }
  .sortby {
    a {
      margin: 0 8px;
    }
  }
}
.cm_gridform {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-md-end;
  @extend .justify-content-start;
  input {
    padding: 4px 5px;
    @extend .text-green;
    @extend .rounded-0;
    border: 1px solid #ebeef1;
    @extend .me-1;
  }
}
.cm_img90rounded {
  @extend .w-90px;
  @extend .h-90px;
  @extend .rounded-circle;
  object-fit: cover;
}
.cm_usertable {
  padding: 15px;
  @include border-radius(5px);
  @extend .bg-white;
  margin-bottom: 25px;
  &:hover {
    @include box-shadow(0px 0px 5px 2px #cccccc);
  }
  .cm_articleTitle {
    @extend .fs-16px;
    a {
      color: #0a405a;
      @extend .text-decoration-none;
      &:hover {
        @extend .text-decoration-underline;
      }
    }
    small {
      @extend .text-green;
      @extend .fs-12px;
    }
  }
  .right_block {
    @media screen and (min-width: 992px) {
      border-left: 1px solid #dee2e6 !important;
    }
  }
}
.cm_detailsinfo {
  min-height: 52px;
  text-align: justify;
  @extend .text-green;
  @extend .fs-13px;
}
.cm-dashboard-btn {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    @extend .fs-12px;
    margin-right: 4px;
    display: inline-block;
    padding: 0.2em 0.6em;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    @extend .rounded-sm;
    a {
      @extend .text-white;
      @extend .text-decoration-none;
      &:hover {
        @extend .text-decoration-underline;
      }
      i {
        @extend .me-1;
      }
    }
  }
}
/*-------------------------------------------
#Analytics
-----------------------------------------------*/
.daterangepicker .ranges {
  margin: 8px;
  li {
    @extend .fs-13px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: #08c;
    padding: 3px 12px;
    margin-bottom: 8px;
    @include border-radius(5px);
    cursor: pointer;
  }
}
/*---------------------------------------------
#IncomingRequests
---------------------------------------------*/
.cm_requestsbtn {
  background: #00354e;
  padding: 5px 10px;
  .btn-light {
    border: none;
    color: #000;
    @extend .bg-white;
    padding: 5px 10px;
    @extend .fs-13px;
    line-height: 1.2;
    border-left: 1px solid #ccc !important;
    &:first-child {
      border: none !important;
    }
    &:hover {
      background: #e6e6e6 !important;
    }
  }
  .btn-warning {
    color: #fff;
    padding: 5px 10px;
    @extend .fs-13px;
    border-color: #eea236;
    line-height: 1.2;
  }
  .btn-group {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > .btn {
        width: 50%;
        border-radius: 0;
        padding: 7px 3px;
        line-height: 1.1;
        &:first-child {
          border-radius: 5px 0 0 0 !important;
        }
        &:nth-child(2) {
          border-radius: 0 5px 0 0 !important;
        }
        &:nth-child(3) {
          border-radius: 0 0 0 5px !important;
        }
        &:last-child {
          border-radius: 0 0 5px 0 !important;
        }
      }
    }
    @media screen and (max-width: 360px) {
      & > .btn {
        font-size: 12px !important;
      }
    }
  }
}
.cm_usertable2 {
  background: #f9f9f9;
  padding: 15px 5px;
  @include border-radius(5px);
  margin-bottom: 25px;
  @media only screen and (max-width: 991px) {
    padding: 15px;
}
  &.disapproved {
    background: #d7d7d7;
  }
  .cm_column1 {
    @extend .text-green;
    @extend .fs-13px;
    line-height: 24px;
    
    a {
      color: #0a405a;
      word-break: break-word;
      @extend .text-decoration-none;
      &:hover {
        @extend .text-decoration-underline;
      }
    }
  }
  .cm_column3 {
    @extend .text-green;
    @extend .fs-13px;
    line-height: 24px;
    a {
      color: #0a405a;
      @extend .text-decoration-none;
      &:hover {
        @extend .text-decoration-underline;
      }
    }
  }
  &:hover {
    @include box-shadow(0px 0px 5px 2px #cccccc);
  }
  .cm_articleTitle {
    @extend .fs-16px;
    a {
      color: #0a405a;
      @extend .text-decoration-none;
      &:hover {
        @extend .text-decoration-underline;
      }
    }
    small {
      @extend .text-green;
      @extend .fs-12px;
    }
  }
}
.cm_tableBlue {
  td,
  th {
    @extend .fs-13px;
  }
  th {
    @extend .text-white;
    border: none;
  }
  td {
    @extend .text-green;
    a {
      @extend .text-green;
      @extend .text-decoration-none;
      &:hover {
        @extend .text-decoration-underline;
      }
    }
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  @extend .text-green;
  background: #f9f9f9;
  --bs-table-accent-bg: #f9f9f9;
}
.table-striped > tbody > tr:first-of-type > th {
  background: #337ab7 !important;
  --bs-table-accent-bg: #337ab7;
}
.show-subscriber-textarea.btn-danger {
  background: #c644fc;
  border: none;
  padding: 0px 10px;
  @extend .fs-13px;
}
@media screen and (max-width: 991px) {
  .border-end {
    border: none !important;
    padding-bottom: 15px;
  }
}

img.aligncenter.wp-image-348.size-full {
  width: 100% !important;
  height: 100% !important;
}

img.aligncenter.wp-image-352.size-large {
  width: 100% !important;
  height: 100% !important;
}
.py-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}
.text-blue {
  color: blue;
}
.pagination {
  @extend .d-flex;
  @extend .flex-wrap;

  li.page-item {
    a {
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
      font-size: 20px;
    }
  }

  .page-item.active {
    .page-link {
      background-color: #048c96;
      border: #048c96;
      font-size: 20px;
    }
  }
}
.cm_messageItem {
  padding: 15px;
  &:nth-child(odd) {
    background: #f9f9f9;
  }
}
.cm_img60pxrounded {
  @extend .w-60px;
  @extend .h-60px;
  object-fit: cover;
  @extend .rounded-circle;
}
.card-header {
  background: $primary;
  @extend .text-white;
}
.linkedinIcon {
  background: url(//static.licdn.com/scds/common/u/images/apps/connect/sprites/sprite_connect_v14.png) -92px -42px
    no-repeat;
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
}
.card-border {
  border-color: #337ab7;
}
.cm_colorInput {
  border-top: 0;
  border-bottom: 0;
  border-right-width: 40px;
  border-left-width: 2px;
  border-style: solid;
}
.cm_tableSplitTesting {
  .cm_colorBox {
    border: 1px solid #ccc;
    padding: 1px;
    div {
      width: 4px;
      height: 0;
      overflow: hidden;
    }
  }
}
.cm_uploadImagItem {
  @extend .w-100px;
  @extend .h-100px;
  @extend .overflow-hidden;
  position: relative;
  margin: 0 4px 4px 0;
  input {
    position: absolute;
    @extend .w-200px;
    @extend .h-100px;
    top: 0;
    left: -100px;
    cursor: pointer;
    @extend .opacity-0;
  }
  img {
    @extend .w-100px;
    @extend .h-100px;
    object-fit: cover;
  }
  .btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    @extend .w-20px;
    @extend .h-20px;
    @extend .rounded-circle;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .p-0;
    @extend .text-green;
    @extend .text-decoration-none;
  }
}
.cm_error {
  margin: 0 0 2em;
  padding: 1em;
  @extend .bg-white;
  border: 3px solid #ed3b3b;
  @extend .rounded-sm;
  @include box-shadow(rgba(#000, 0.03) 0 2px 0);
}
/*---------------------------------------28-1-2022------------------------------------------*/
.fa-star {
  &.empty {
    color: #ccc;
  }
  &.full {
    color: $green;
  }
}
/*-----------------------2-2-22---------------------*/
.successfullyMessage {
  background: #00354e;
  margin: -40px;
  padding: 40px;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  @include animation(scaleOut 1s cubic-bezier(0.7, 0, 0.3, 1) forwards);
}

@include keyframes(scaleOut) {
  from {
    @include transform(scale3d(1.5, 1.5, 1));
  }
  to {
    @include transform(scale3d(1, 1, 1));
  }
}

.text-red.cm_boxTitle.mb-3 {
  color: red !important;
}

.loader {
  position: fixed;
  display: none !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  //background: #ffffffa8;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .d-flex;
  // @include transform(translate(-50%, -50%));
}

.loader-inner {
  top: 50%;
  bottom: 50%;
  position: fixed;
  left: 50%;
}
.topLeft {
  @extend .position-absolute;
  top: 0;
  left: 0;
}
.bg_lightBlue {
  th {
    border: none;
  }
}
.hover-text-decoration-underline:hover {
  text-decoration: underline !important;
}
.cm_input.arrow {
  background-image: url("./assets/images/chevron-down.svg");
  background-position: calc(100% - 10px) 50%;
  background-size: 15px;
  background-repeat: no-repeat;
}
.cmn-Pagination {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  .disabled {
    a {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
    }
  }
  li {
    a {
      @extend .d-block;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
      font-size: 20px;
    }
    &.selected {
      a {
        background-color: #048c96;
        border: #048c96;
        font-size: 20px;
        color: #fff;
      }
    }
  }
}

#left_list {
  background: #fcf;
  h3 {
    @extend .fs-15px;
    margin: 1.25em 0;
    padding: 0 0 0.75em;
    border-bottom: 2px solid #e9edf2;
    line-height: 1.0909090909;
    color: $green;
  }
}
#right_list {
  background: #afa;
  h3 {
    @extend .fs-15px;
    margin: 1.25em 0;
    padding: 0 0 0.75em;
    border-bottom: 2px solid #e9edf2;
    line-height: 1.0909090909;
    color: $green;
  }
}
.drugContaner {
  @extend .h-100;
  @extend .min-h-100;
  @extend .list-unstyled;
  li {
    @extend .position-relative;
    cursor: pointer;
    @extend .d-block;
    padding: 10px 15px;
    margin-bottom: -1px;
    @extend .bg-white;
    border: 1px solid #ddd;
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

.copyList {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;

  a {
    color: #0a405a;
    text-decoration: none;
  }

  a:hover {
    color: #002741;
    text-decoration: underline;
  }
}

/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}
input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}

input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

.contentInvite_both {
  color: #377172;
  font-size: 13px;
  font-weight: 500;
  margin-top: 12px;
}

.contentInvite_both input[type="radio"] {
  border-style: solid;
  border-width: 2px;
  background: #fff;
  color: #555;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 20px;
  margin: -4px 4px 0 0;
  outline: 0;
  padding: 4px !important;
  padding-top: 10px !important;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  min-width: 20px;
  transition: 0.05s border-color ease-in-out;
  border-radius: 50%;
  margin-left: 14px;
  margin-right: 6px;
}

.errorInvite {
  position: relative;
  top: -16px;
}

#SvgjsSvg1001 {
  display: none;
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  display: none !important;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  color: #000 !important;
  font-weight: normal !important;
  font-size: 14px !important;
  z-index: 99999999;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 0px;
  margin: 0;
  margin-left: -30px;
}
div.ReactTags__suggestions li mark {
  text-decoration: none;
  background: none;
  padding: 0% !important;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
.wrapper404 {
  width: 100%;
  margin: 30px auto;
  // transform: scale(0.8);
}
.landing-page404 {
  width: 100%;
  height: 600px;
  margin: 0;
  box-shadow: 0px 0px 0px 1px #ccc;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.svg404 {
  width: 100%;
  max-height: 270px;
  height: auto;
  margin: 0 0 15px;
}
.h1404 {
  font-size: 48px;
  margin: 0;
}
.p404 {
  font-size: 16px;
  width: 35%;
  margin: 16px auto 24px;
  text-align: center;
}
.button404 {
  border-radius: 50px;
  padding: 8px 24px;
  font-size: 16px;
  cursor: pointer;
  background: #11364e;
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px 0 #ccc;
  text-decoration: none;

  &:hover {
    color: white;
  }
}

.article-image {
  position: relative;
  margin-bottom: -20px;

  img {
    width: 100%;
  }

  label {
    text-align: center;
    color: grey;
  }
}

.article-video {
  text-align: center;
}

p.video-description {
  width: 100%;
  text-align: center;
  color: grey;
  margin: 0px;
}

.svelte-1o88d5s {
  display: none !important;
}

.multi-range-slider {
  box-shadow: none !important;
  border: none !important;
}

.multi-range-slider .bar-inner {
  background-color: #7af0ff !important;
}

.multi-range-slider .label-min {
  position: absolute !important;
  top: 15px !important;
  left: -28px !important;
}

.multi-range-slider .label-max {
  position: absolute !important;
  top: 15px !important;
  right: -28px !important;
}
.wallet_address {
  @media only screen and (max-width: 991px) {
    font-size: calc(1.1rem + 0.3vw);
  }
}
.crypto-connect {
  border-color: #ccc !important;
  img {
    width: 27px;
    padding-right: 10px;
  }
}

.badge {
  background-color: #777;
  @include border-radius(10px);
}

.save-btn-content {
  width: 61px !important;
}

.article_applications {
  border: 1px #eee solid;
  padding: 10px;
  margin: 10px 0px;
  color: #377172;
  font-size: 14px !important;
}

.bg-success-rp {
  background-color: #dff0d8;
}

.bg-info-rp {
  background-color: #d9edf7;
}

.feed-profile-image {
  width: 15% !important;
}

.category-Item {
  white-space: break-spaces;
}

.cm-headline {
  overflow: hidden;
  vertical-align: top
}
.cm-headline {
  display: inline-block;
  position: relative;
  width: 335px;
  text-align: left
}

@media only screen and (max-width: 990px) {
  .cm-headline {
    display: inline-block;
    position: relative;
    width: 255px;
    text-align: center
  }
}

.cm-headline span {
  display: inline-block;
  position: absolute;
  white-space: normal;
  left: 0;
  top: 0
}
.cm-headline span.is-visible {
  position: relative
}
.cm-headline.slide span {
  opacity: 0;
  top: .2em
}
.cm-headline.slide span.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in .6s;
  animation: slide-in .6s
}
.cm-headline.slide span.is-hidden {
  -webkit-animation: slide-out .6s;
  animation: slide-out .6s
}
@-webkit-keyframes slide-in {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%)
  }
  60% {
      opacity: 1;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}
@keyframes slide-in {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%)
  }
  60% {
      opacity: 1;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}
@-webkit-keyframes slide-out {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
  60% {
      opacity: 0;
      -webkit-transform: translateY(120%);
      transform: translateY(120%)
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%)
  }
}
@keyframes slide-out {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
  60% {
      opacity: 0;
      -webkit-transform: translateY(120%);
      transform: translateY(120%)
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%)
  }
}

.Differences thead th {
  text-align: left;
  border-bottom: 1px solid #000;
  background: #337ab7;
  color: #FFF;
  padding: 4px;
}

.DifferencesSideBySide .ChangeReplace .Left  {
  background: #fe9;
}

.DifferencesSideBySide .ChangeReplace .Right {
  background: #fd8;
}

.DifferencesSideBySide .ChangeReplace del, .DifferencesSideBySide .ChangeReplace ins {
  background: #fc0;
}



/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px !important;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .switch-slider {
  background-color: #448991;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #448991;
}

input:checked + .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch-slider.switch-round {
  border-radius: 34px;
}

.switch-slider.switch-round:before {
  border-radius: 50%;
}

.Toastify__toast-body {
  word-break: break-word; 
}

.cm_USDT {
  padding-left: 3px !important;
  margin-right: -26px !important;
}

.transationStatusDot {
  background-color: red;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-left: 4px;
  margin-bottom: 10px;
}

.img-description a {
  text-decoration: solid !important;
  color: #999999 !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #c2c6ce !important;  
}

.text-font-size18 {
  font-size: 18px !important;
}